import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Mathematics } from './mathematics.service';
import { ThematicKeySymbol } from './thematic-key-symbol.service';
import { ScrollIntoViewService } from './scrollIntoView.service';
import { ColorsService } from './colors.service';
import { MapBoxService } from './map-box.service';
import { MatTableService } from './mat-table.service';
import { LocationService } from './location.service';
import { ZIndexService } from './z-index.service';
@NgModule({
  imports: [CommonModule],
  providers: [Mathematics, ThematicKeySymbol, ScrollIntoViewService, ColorsService, MapBoxService, MatTableService, LocationService, ZIndexService]
})
export class UtilsMiscModule { }

export function merge(target: any, ...sources: any[]): any {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (Array.isArray(target) && Array.isArray(source)) {
    // Si ambos son arrays, combina los valores de los arrays
    for (let i = 0; i < source.length; i++) {
      if (typeof target[i] === 'undefined') {
        target[i] = source[i];
      } else if (typeof target[i] === 'object' && typeof source[i] === 'object') {
        // Fusionar objetos dentro de los arrays
        merge(target[i], source[i]);
      } else {
        // Si no es un objeto, sobrescribe el valor
        target[i] = source[i];
      }
    }
  } else if (typeof target === 'object' && typeof source === 'object') {
    // Si ambos son objetos, fusionar objetos
    for (const key in source) {
      if (source[key] && typeof source[key] === 'object') {
        if (!target[key]) {
          Object.assign(target, { [key]: Array.isArray(source[key]) ? [] : {} });
        }
        merge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return merge(target, ...sources);
}


export function uniq(array: any[]): any[] {
  return Array.from(new Set(array));
}

export function get(obj: any, path: string, defaultValue?: any): any {
  const pathArray = path.split('.'); // Dividir el path por los puntos
  let result = obj;

  for (const key of pathArray) {
    result = result ? result[key] : undefined;
    if (result === undefined) {
      return defaultValue;
    }
  }

  return result;
}
