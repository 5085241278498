import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MarkerOptionsApiService } from '@compass/utils/navigation';
import * as d3 from 'd3';
import { SidenavPoisLevelsService } from './sidenav-pois-levels.service';
import {merge, uniq} from '@compass/utils/misc'
// restaurants, banks, pharma, schools
export interface Poi {
  key: string;
  name: string;
  selected: boolean;
  category: string;
}

export interface Pois extends Array<Poi> {
}

export interface PoiInterface {
  type: string;
  properties: any | {
    categoria: string;
    class_: string;
    direccion: string;
    ensenya_desc: string;
    icono: string;
    id: number;
    id_alimarket: number;
    id_categoria: number;
    id_ensenya: number;
    key_categoria: string;
    key_ensenya_desc: string;
    key_sub_categoria: string;
    nombre: string;
    selected: boolean;
    source: string;
    sub_categoria: string;
    sup_m2: number;
    category: string;
    color?: string;
  };
  geometry: {
    type: string;
    coordinates: Array<number>;
  };
}


@Injectable({ providedIn: 'root' })
export class SidenavPoisApiService {
  // TRANSPORT
  public transportList$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );

  constructor(
    private http: HttpClient,
    private markerOptionsApiService: MarkerOptionsApiService,
    private sidenavPoisLevelsService: SidenavPoisLevelsService
  ) {
  }

  handleError(error: HttpErrorResponse) {
    const kk = null;
    return of(kk);
  }

  // NEST NEW CATEGORIES
  nestCategories(data, key: string) {
    //console.log(data,"data",d3)
    return d3
      .nest()
      .key((d) => {
        return d.properties[key];
      })
      .sortKeys(d3.ascending)
      .key((d) => {
        return d.properties.key_sub_categoria;
      })
      .sortKeys(d3.ascending)
      .key((d) => {
        return d.properties.key_ensenya_desc;
      })
      .sortKeys(d3.ascending)
      .entries(data);
  }

  // MAP NEW CATEGORIES

  reduceNodes(leafNodes, level, key) {
    return leafNodes.filter(
      (leafNode) =>
        leafNode.properties[level] === key && leafNode.properties.selected
    );
  }


  // count the categories pois recursive
  countCategoriesPois(categories: any[], parentCategory?: any) {
    return categories.map(category => {
      // if dont have values is a POI
      if (category.values === undefined) {
        parentCategory.totalPois += 1;
        category.totalPois = undefined;
      } else {
        category.totalPois = 0;
        category.values = this.countCategoriesPois(category.values, category);
        // foreach subcategory, get the total pois
        category.values.forEach((subCategory: any) => {
          category.totalPois += subCategory.totalPois ? subCategory.totalPois : 0;
        });
      }

      return category;
    });
  }

  setFourLevelsProps(data, leafNodes, selectedKeys = []) {
    selectedKeys = merge(selectedKeys, this.sidenavPoisLevelsService.selected ?? []);
    selectedKeys = uniq(selectedKeys);

    data = this.countCategoriesPois(data);

    data = data.map((poi) => {
      let categoriesNodes = this.reduceNodes(leafNodes, 'key_categoria', poi.key);
      categoriesNodes.length ? (poi['selected'] = true) : (poi['selected'] = false);

      poi['key'] = poi.key;
      poi['level'] = 'categories';
      poi['name'] =
        poi.values[0].values[0].values[0].properties.categoria ??
        poi.values[0].values[0].values[0].properties.category;
      poi['category'] = poi.key;
      poi['selected'] = poi.selected || selectedKeys.includes(poi.key);

      // poi['markerOptions'] = this.getMarkerOptions(poi, 'key');
      poi['values'].map((subpoi) => {
        let subcategoriesNodes = this.reduceNodes(leafNodes, 'key_sub_categoria', subpoi.key);
        subcategoriesNodes.length ? (subpoi['selected'] = true) : (subpoi['selected'] = false);
        subpoi['level'] = 'sub_categories';
        subpoi['key'] = subpoi.key;
        subpoi['category'] = poi.key;
        //v['color'] = d.color;
        //subpoi['markerOptions'] = poi.markerOptions;
        subpoi['name'] =
          subpoi.values[0].values[0].properties.sub_categoria ??
          subpoi.values[0].values[0].properties.subcategory;

        subpoi['selected'] = subpoi.selected || selectedKeys.includes(subpoi.key);

        subpoi['values'] && subpoi['values'].map((company) => {
          //console.log(company)
          let companiesNodes = this.reduceNodes(leafNodes, 'key_ensenya_desc', company.key);
          company['selected'] = !!companiesNodes.length;
          company['level'] = 'companies';
          company['key'] = company.values[0].properties.key_ensenya_desc;
          company['category'] = poi.key;
          company['subcategory'] = subpoi.key;
          //company['markerOptions'] = poi.markerOptions;
          company['name'] = company.values[0].properties.nombre;
          company['selected'] = company.selected || selectedKeys.includes(company.key);

          //z['color'] = d.color;
          company['values'] && company['values'].map((place) => {
            let placesNodes = this.reduceNodes(leafNodes, 'id', place.properties.id);
            place['selected'] = !!placesNodes.length;
            place['level'] = 'places';
            place['key'] = place.properties.id;
            place['category'] = poi.key;
            place['subcategory'] = subpoi.key;
            place['company'] = company.key;
            place['markerOptions'] = this.markerOptionsApiService.getMarkerOptions(place);
            place['name'] = place.properties.nombre;

            place['selected'] = place.selected || selectedKeys.includes(place.key);
            //z['color'] = d.color;
            return place;
          });

          return company;
        });

        return subpoi;
      });

      return poi;
    });

    return data;
  }

  /**** TRANSPORT WITH TWO LEVELS */
  setTwoLevelsProps(data, leafNodes) {
    return data.map((poi) => {
      let categoriesNodes = this.reduceNodes(
        leafNodes,
        'key_sub_categoria',
        poi.key
      );
      categoriesNodes.length
        ? (poi['selected'] = true)
        : (poi['selected'] = false);
      poi['key'] = poi.key;
      poi['level'] = 'categories';
      poi['name'] = poi.values[0].properties.sub_categoria;
      poi['category'] = poi.key;
      //poi['markerOptions'] = this.getMarkerOptions(poi, 'key');
      poi['values'] &&
      poi['values'].map((subpoi) => {
        let subcategoriesNodes = this.reduceNodes(
          leafNodes,
          'id',
          subpoi.properties.id
        );

        subpoi['selected'] = !!subcategoriesNodes.length;
        subpoi['level'] = 'subcategories';
        subpoi['key'] = subpoi.properties.id;
        subpoi['category'] = poi.key;
        //v['color'] = d.color;
        subpoi[
          'markerOptions'
          ] = this.markerOptionsApiService.getMarkerOptions(subpoi);
        subpoi['name'] = subpoi.properties.nombre;
        return subpoi;
      });
      return poi;
    });
  }

  // NEST LOCAL POIS AND TRANSPORT: TWO LEVELS
  nestTwoLevelsPois(data, key: string) {
    return d3
      .nest()
      .key((d) => {
        return d.properties[key];
      })
      .sortKeys(d3.ascending)
      .entries(data);
  }
}
